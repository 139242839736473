import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Home from "./components/homeComponent/Home";
import About from "./components/aboutComponent/About";
import Staff from "./components/staffComponent/Staff";

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
            <a className="navbar-brand" href="/">Zion Hill Missionary Baptist Church</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01"
                    aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarColor01">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                  <Link to="/" className="nav-link" >Home <span className="sr-only">(current)</span></Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className="nav-link">About</Link>
                </li>
                <li className="nav-item">
                  <Link to="/staff" className="nav-link">Staff</Link>
                </li>
              </ul>
            </div>
          </nav>
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/staff" component={Staff} />
        </div>
      </Router>
    );
  }
}

export default App;
