import React, { Component } from 'react';

class Staff extends Component {
    render() {
        return (
            <div>
                <h1>This is the staff placeholder</h1>
            </div>
        )
    }
}

export default Staff;